<template>
  <div>
    <data-list :category="category" detailRouter="/member-news-detail"></data-list>
  </div>
</template>

<script>
import dataList from '@/components/DataList'

export default {
  name: 'MemberNews',

  components: { dataList },

  data () {
    return {
      category: 'MemberNews'
    }
  }
}
</script>

<style scoped>
</style>
